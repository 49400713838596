import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { globalPutService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import { PageLoader } from '../../../shared_elements'

export default function StatusChangeDialog({ invStatus, setStatusChangeList, getResponseBack, invData}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ isLoading, setLoading ] = useState(false);

    const invoiceStatus =[{label:'Draft',value:1},{label:'Sent',value:2},{label:'Paid',value:3}]
    const onInvStatusChange =(value)=>{
        setLoading(true)
        globalPutService(`contracts/summary-invoice/${invData?.slug}/status-update/`, {status:value})
        .then(response=>{
            setLoading(false)
            if(checkApiStatus(response)){
                getResponseBack()
                setStatusChangeList(false)
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        })
    }
    return (
        <>
        {isLoading ? <PageLoader />: null}
        <Dialog
            onClose={() => setStatusChangeList(false)}
            open={true}
            className='lease-management-modal'
        >
            <DialogTitle> Change Invoice Status</DialogTitle>
            <DialogContent dividers='true' style={{width:'400px'}}>
                <Autocomplete
                    style={{ marginTop: '16px' }}
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Invoice Status" placeholder="Select Status" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
            </DialogContent>
            <DialogActions>
            <Button size='small' variant="outlined" color="primary" onClick={() => { setStatusChangeList(false) }}>
                Cancel
            </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}
