import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { globalExportService, globalGetService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LabelValueCard, PageLoader } from '../../../shared_elements'
import { downloadFileType } from '../../../utils_v2'
import moment from 'moment'
import { displayDateFormat, fieldDateFormat } from '../../../constants'
import { browserHistory } from 'react-router'
const InvoiceItemList = ({ item, index }) => {
    return (
        <TableRow>
            <TableCell>{item?.invoice_item_type_id?.name || '--'}</TableCell>
            <TableCell>{item?.description || '--'}</TableCell>
            <TableCell>{item?.unit_type || '--'}</TableCell>
            <TableCell>{item?.no_of_unit || '--'}</TableCell>
            <TableCell>{item?.rate_per_unit || '--'}</TableCell>
            <TableCell>{item?.tax_rate || '--'}</TableCell>
            <TableCell>{item?.tax_amount || '--'}</TableCell>
            <TableCell>{item?.sub_total || '--'}</TableCell>
        </TableRow>
    )
}
export default function SummaryInvoiceView({ params }) {
    useEffect(() => {
        getInvoiceDetails()
    }, [])
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const getInvoiceDetails = () => {
        setLoading(true)
        globalGetService(`contracts/summary-invoice/${params.slug}/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setInvoiceInfo(response.data.data)
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const exportInvoice = () => {
        setLoading(true)
        globalExportService(`contracts/summary-invoice/${params.slug}/?download=pdf`)
            .then(response => {
                setLoading(false)
                downloadFileType(response, `Summary_invoice_${invoiceInfo?.invoice_number}`, 'pdf')
            })
    }
    return (
        <div className='summary-invoice-container'>
            {isLoading ? <PageLoader /> : null}
            <div className='smr-inv-pg'>
                <Grid container spacing={1}>
                    <Grid item sm='6'>
                        <div style={{cursor:"pointer"}} onClick={()=>browserHistory.push('/invoices/?tabIndex=2')} >
                            <div style={{display:'flex', alignItems:'center'}}> <ArrowBackIcon /> Summary Listing</div>
                            Invoice # {invoiceInfo?.invoice_number}
                        </div>
                    </Grid>
                    <Grid item sm='6' style={{ textAlign: 'right' }}>
                        <Button style={{ marginBottom: '13px' }} variant="outlined" color="primary" onClick={() => { exportInvoice() }}>
                            Export
                        </Button>
                    </Grid>
                    <LabelValueCard md={3} label='Invoice Number' value={invoiceInfo?.invoice_number || '--'} />
                    <LabelValueCard md={3} label='Invoice Status' value={invoiceInfo?.status?.label || '--'} />
                    <LabelValueCard md={3} label='Invoice Date' value={invoiceInfo?.start_date ? moment(invoiceInfo?.start_date).format(displayDateFormat) : '--'} />
                    <LabelValueCard md={3} label='Invoice Due Date' value={invoiceInfo?.due_date ? moment(invoiceInfo?.due_date).format(displayDateFormat) : '--'} />
                    <LabelValueCard md={3} label='Assets' value={invoiceInfo?.assets ? invoiceInfo?.assets.map((item, index)=> (index+1) < invoiceInfo?.assets.length ?`${item}, `:`${item}`) : '--'} />
                </Grid>
                <Grid container spacing={1} style={{ margin: "20px 0px" }}>
                    <Grid item sm='6' style={{ paddingRight: '25px' }}>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">From Lessor/Holdco</h4>
                            <div className='lessee-lessor-data'>
                            <LabelValueCard md={12} label='Name' value={invoiceInfo?.from_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.from_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.from_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm='6'>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">To (Lessee)</h4>
                            <div className='lessee-lessor-data'>
                                <LabelValueCard md={12} label='Name' value={invoiceInfo?.to_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.to_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.to_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='receiavables-head'>
                    USD recievables
                </div>
                <Table className='inv-smr-list'>
                    <TableHead>
                        <TableRow >
                            <TableCell>Item</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Unit Description	</TableCell>
                            <TableCell>No of Days</TableCell>
                            <TableCell>Fixed Rate (USD)	</TableCell>
                            <TableCell>Tax Rate (%)	</TableCell>
                            <TableCell>Tax Amount 	</TableCell>
                            <TableCell>Sub Total (USD)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{
                        invoiceInfo?.invoice_items?.map((item, index) =>
                            <InvoiceItemList
                                item={item}
                                index={index}
                            />)}
                    </TableBody>
                </Table>
                <div className='sub-total-inv-smr' style={{ textAlign: 'right' }}>
                    <h5>Billable Amount : US${invoiceInfo?.amount || '0'}</h5>
                    <h5>Tax :US${invoiceInfo?.tax_amonut || '0'}</h5>
                    <h4>Total :US${invoiceInfo?.amount || '0'}</h4>
                </div>
                <div className='bank-details-head'>
                    <h5>Bank Detail For USD Rental Receivable</h5>
                </div>
                <Grid container spacing={1} className='bank-details-section-smr'>
                    <LabelValueCard md={3} label='Name' value={invoiceInfo?.bank_name || '--'} />
                    <LabelValueCard md={3} label='Account Name' value={invoiceInfo?.bank_account_name || '--'} />
                    <LabelValueCard md={3} label='Account Number' value={invoiceInfo?.bank_account_number || '--'} />
                    <LabelValueCard md={3} label='IBAN Code' value={invoiceInfo?.bank_iban || '--'} />
                    <LabelValueCard md={3} label='ABA Number' value={invoiceInfo?.aba_number || '--'} />
                    <LabelValueCard md={3} label='SWIFT Code' value={invoiceInfo?.bank_swift_code || '--'} />
                    <LabelValueCard md={3} label='SORT Code' value={invoiceInfo?.bank_sort_code || '--'} />
                    <LabelValueCard md={3} label='Footer Note' value={invoiceInfo?.footer_note || '--'} />
                </Grid>
            </div>
        </div>
    )
}
