export const numberRegx = /^[+-]?((\.\d+)|(\d+(\.+)?(\d+)?)|(\d))$/;
export const float2DecRegx = /^[0-9]*(\.[0-9]{0,2})?$/;
export const floatMRDecRegx = /^[0-9]*(\.[0-9]{0,8})?$/;
export const onlyNumeric = /^(\s*|\d+)$/;
export const intRegx = /^[0-9]?\d+$/;
export const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const fieldDateFormat = 'DD-MM-YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MMM DD, YYYY';
export const displayDateFormatCard = 'MMM DD';
export const displayDateFormatCardYear = 'YYYY';
export const displayDateTimeFormat = "MMM DD, YYYY";
export const displayDateFormatShort = 'MMM DD, YYYY';
export const displayDateTimeFormatShort = 'MMM DD, YYYY, HH:mm';

// Constants Currency Format
export const currencyFormat = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true};
export const euroCurrencyFormat = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currencyIndicator: '€', currency: true, commafy: true};
export const prettyNumberParamsL = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true};
export const prettyNumberParamsDB = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, shortFormat:true, shortFormatMinValue: 99999, commafy: true};
export const utilizationFormat = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: false, commafy: false};
export const prettyNumberParamsR = {cssClass:["pretty-number-override-css"], justification:'R', currency: true, commafy: true};

//Constants Note Text Across Forms
export const noteFormsText = "Please note autosave feature enabled on this form. Changes will be saved automatically.";


export const toastTimeOut = 10000;
// dev urls
// export const mediaBaseUrl = "https://dev.beta.sparta.aero:8222";
// export const unlimitedBaseUrl = "https://dev.beta.sparta.aero:8222";
// export const downloadBaseUrl = "https://sparta-aims.ams3.digitaloceanspaces.com/others";
// export const privacyPolicyUrl = "https://dev.beta.sparta.aero";
// export const toolsBaseUrl = "https://dev.beta.sparta.aero";
// export const signUpBaseUrl = "https://dev.beta.sparta.aero:8222";
// export const imgStoragePath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/';
// export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
// export const stripeKey = 'pk_test_zRHpySDLN4O8fmUEWE5mSzt900SqFO1hMG'

//qa urls
export const mediaBaseUrl = "https://qa.beta.sparta.aero:8223";
export const unlimitedBaseUrl = "https://qa.beta.sparta.aero:8223";
export const downloadBaseUrl = "https://sparta-aims.ams3.digitaloceanspaces.com/others";
export const privacyPolicyUrl = "https://qa.beta.sparta.aero";
export const toolsBaseUrl = "https://qa.beta.sparta.aero";
export const signUpBaseUrl = "https://qa.beta.sparta.aero:8223";
export const imgStoragePath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/';
export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
export const stripeKey = 'pk_test_zRHpySDLN4O8fmUEWE5mSzt900SqFO1hMG'

//production urls
// export const mediaBaseUrl = "https://api.sparta.aero";
// export const unlimitedBaseUrl = "https://sftp.sparta.aero";
// export const downloadBaseUrl = "https://sparta-aims.ams3.digitaloceanspaces.com/others";
// export const privacyPolicyUrl = "https://sparta.aero";
// export const toolsBaseUrl = "https://sparta.aero";
// export const signUpBaseUrl = "https://api.sparta.aero";
// export const imgStoragePath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/';
// export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
// export const stripeKey = 'pk_live_ixftLlEfCTaRpjk1AiPfp2BN00DTPaUe0p'

export const assetsType = {
  1: 'aircraft',
  2: 'engine',
  3: 'apu',
  4: 'lg',
  5: 'propeller',
  6: 'fan',
  7: 'hpc',
  8: 'hpt',
  9: 'lpt'
}

export const statusLabel = {
  'On Lease': 'green',
  'Draft': 'blue'
}

export const assetId = {
  aircraft : 1,
  engine : 2,
  apu : 3,
  lg : 4,
  propeller : 5,
  fan : 6,
  hpc : 7,
  hpt : 8,
  lpt : 9
}
export const referalImages = {
  1:  'phase-2/user_license.png',
  2:  'phase-2/user_license.png',
  4:  'phase-2/asset_license.png',
  3:  'phase-2/asset_license.png',
  6:  'phase-2/inspection_license.png',
  13: 'phase-2/inspection_license.png',
  18: 'phase-2/asset_license.png',
  19: 'phase-2/asset_license.png',
}

export const notificationTypes = {
  3:'trial expiry',
  4: 'payment success',
  5: 'plan expiry',
  6: 'payment fail',
  7: 'usage limit',
  8: 'New Feature Announcement'
}
export const notificationTypeUrls = {
  3:'trial expiry',
  4: '/billing/invoice/detail/',
  10: '/billing/invoice/detail/',
  5: 'plan expiry',
  6: 'payment fail',
  7: 'usage limit'
}

export const freeTrialRedirects = {
  'user': '/users/manage',
  'storage': '/assets-listing',
  'inspections': '/technical/projects?project_status=all',
  'mr-calculator' : '/logs',
  'fleet-cashflow': '/fleet-cashflow/portfolios/',
  'contracts' : '/assets-listing',
  'maintenance' : '/assets-listing',
  'asset-data-transfer' : '/assets-listing',
}

export const licences = [
  {id: 1, name: 'user'},
  {id: 2, name: 'storage'},
  {id: 3, name: 'inspections'},
  {id: 4, name: 'mr-calculator'},
  {id: 5, name: 'fleet-cashflow'},
  {id: 6, name: 'contracts'},
  {id: 7, name: 'maintenance'},
  {id: 8, name: 'asset-data-transfer'},
]

export const macDriveTool =  downloadBaseUrl + '/tools/drive/SPARTA.aero_Drive.dmg'
export const window32DriveTool = downloadBaseUrl + '/tools/drive/SPARTA.aero_Drive_32.msi'
export const window64DriveTool = downloadBaseUrl + '/tools/drive/SPARTA.aero_Drive.msi'

export const imageExtensions = ['gif','bmp','jpeg','jpg','png','tiff'];

export const recordCategories = ['technical','technical','discrepancies','contracts','inspections']

export const fileIcons = {
  'pdf':  imgStoragePath+"pdf_file.png",
  'xls':  imgStoragePath+"xls_icon.png",
  'xlsm':  imgStoragePath+"xls_icon.png",
  'xlsx':  imgStoragePath+"xls_icon.png",
  'csv':  imgStoragePath+"xls_icon.png",
  'doc':  imgStoragePath+"docs_icon.png",
  'docx':  imgStoragePath+"docs_icon.png",
  'jpg':  imgStoragePath+"default_file_icon.png",
  'jpeg':  imgStoragePath+"default_file_icon.png",
  'png':  imgStoragePath+"default_file_icon.png",
  'gif':  imgStoragePath+"default_file_icon.png",
  'ppt':  imgStoragePath+"default_file_icon.png",
  'pptx':  imgStoragePath+"default_file_icon.png",
  'tiff':  imgStoragePath+"default_file_icon.png",
  'txt':  imgStoragePath+"default_file_icon.png",
  'rtf':  imgStoragePath+"default_file_icon.png",
  'mov':  imgStoragePath+"video_play.png",
  'mp4':  imgStoragePath+"video_play.png",
  'mpeg':  imgStoragePath+"video_play.png",
  'mpg':  imgStoragePath+"video_play.png",
  'vob':  imgStoragePath+"video_play.png",
  'zip': imgStoragePath+"zip.png",
  'folder': imgStoragePath+"folder_icn3.png",
}
export const currencyType = {
  'USD': 'USD',
  'EUR': 'EUR'
}
export const currencyList = [
  {
    label:'USD',
    value: 'USD'
  },
  {
    label: 'EUR',
    value: 'EUR'
  }
];
export const projectStatus = [
  {
    label:'All',
    value: 'all'
  },
  {
    label: 'Up coming',
    value: 'upcoming'
  },
  {
    label: 'Overdue',
    value: 'overdue'
  },
  {
    label: 'On going',
    value: 'ongoing'
  },
  {
    label: 'Submitted',
    value: 'submitted'
  },
  {
    label: 'Completed',
    value: 'completed'
  },
  {
    label: 'Archived',
    value: 'archived'
  }
];
export const days = [
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6},
  {label: 7, value: 7},
  {label: 8, value: 8},
  {label: 9, value: 9},
  {label: 10, value:10},
  {label: 11, value:11},
  {label: 12, value:12},
  {label: 13, value:13},
  {label: 14, value:14},
  {label: 15, value:15},
  {label: 16, value:16},
  {label: 17, value:17},
  {label: 18, value:18},
  {label: 19, value:19},
  {label: 20, value:20},
  {label: 21, value:21},
  {label: 22, value:22},
  {label: 23, value:23},
  {label: 24, value:24},
  {label: 25, value:25},
  {label: 26, value:26},
  {label: 27, value:27},
  {label: 28, value:28},
  {label: 29, value:29},
  {label: 30, value:30}
];
export const monthsObj = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};
export const monthsRevObj = {
  'Jan': '1',
  'Feb': '2',
  'Mar': '3',
  'Apr': '4',
  'May': '5',
  'Jun': '6',
  'Jul': '7',
  'Aug': '8',
  'Sep': '9',
  'Oct': '10',
  'Nov': '11',
  'Dec': '12'
};

export const assetStatus= JSON.parse(localStorage.getItem('userInfo')) && JSON.parse(localStorage.getItem('userInfo')).defaultLessor && JSON.parse(localStorage.getItem('userInfo')).defaultLessor.id === 348 ? {
  1: {'label': 'OPERATIONAL', 'class': 'on-lease'},
  3: {'label': 'AOG', 'class': 'stored'},
}:JSON.parse(localStorage.getItem('userInfo')) && JSON.parse(localStorage.getItem('userInfo')).defaultLessor && JSON.parse(localStorage.getItem('userInfo')).defaultLessor.id === 442 ?{
  16: {'label': 'Written Off', 'class': 'written-off' },
  17: {'label': 'Sold' , 'class': 'written-off'},
  14: {'label': 'Part out', 'class': 'written-off'},
  2: {'label': 'OFF LEASE', 'class': 'off-lease'}, 
  1: {'label': 'ON LEASE' , 'class': 'on-lease'},
  15: {'label' :'Installed Only', 'class': 'stored'},
}:{
 0: {'label': 'DRAFT', 'class': 'on-lease'},
 1: {'label': 'ON LEASE', 'class': 'on-lease'},
 2: {'label': 'OFF LEASE', 'class': 'off-lease'},
 3: {'label': 'On Ground', 'class': 'stored'},
 4: {'label': 'WRITTEN OFF / SOLD', 'class': 'written-off'}
};

export const creditClassification = [
  {value: 'Green', label: 'Green', id: '1'},
  {value: 'Yellow', label: 'Yellow', id: '2'},
  {value: 'Red', label: 'Red', id: '3'}
];


export const rentalTypeDropdown = [
  {value: 1, label: 'Fixed'},
  {value: 2, label: 'Custom ( With Payment Schedule )'},
  {value: 3, label: 'Power By Hour'},
  {value: 5, label: 'Custom Power By Hour'},
  {value: 4, label: 'Flexi'},
]

export const intervalList = {
  1: 'Monthly',
  2: 'Flight Hour',
  3: 'Flight Cycle',
}
export const intervalDropdown = [
  {value: 5, label: 'Daily'},
  {value: 3, label: 'Flight Cycle'},
  {value: 2, label: 'Flight Hour'},
  {value: 1, label: 'Monthly'},
  {value: 4, label: 'Whichever Is Greater'},
]

export const escalationIntervalDropdwn = [
  {value: 1, label: 'Annual'},
  {value: 2, label: 'Anniversary'},
]

export const escalationIntervalLst = {
  1: 'Annual',
}

export const claimStatusLst = {
  1 : 'Pending',
  2 : 'Success'
}
export const claimStatusDrpdwn = [
  {value: 1, label: 'Pending'},
  {value: 2, label: 'Success'},
]

export const leaseTypeLst = {
  1: 'Dry Lease',
  2: 'Wet Lease',
  3: 'Operating Lease'
}

export const leaseTypeDrpdwn = [
  {value: 1, label: 'Dry Lease'},
  {value: 2, label: 'Wet Lease'},
  {value: 3, label: 'Operating Lease'},
]
export const invoiceStatusList = {
  1: 'Draft',
  2: 'Sent',
  5: 'Partially-Paid',
  3: 'Paid',
  4: 'Cancelled',

};

export const invoiceDashboardStatus = {
  1: 'Draft',
  2: 'Not yet Due',
  3: 'Paid',
  4: 'Cancelled'
};

export const invoiceStatusDrpdwn = [
  {value: 1, label: 'Draft'},
  {value: 2, label: 'Sent'},
  {value: 5, label: 'Partially-Paid'},
  {value: 3, label: 'Paid'},
  {value: 4, label: 'Cancelled'},

];

export const invoiceTypeList = {
  1: 'Engine',
  2: 'Airframe',
  3: 'APU',
  4: 'Landing Gears'
}

export const invoiceDescList = {
  1: 'Reserve',
  2: 'Rental',
  3: 'Penalty',
  4: 'Debit Note',
  5: 'Credit Note',
  6: 'Security Deposit',
  7: 'Insurance',
  8: 'Insurance Claim',
  9: 'AdHoc',
}

export const invoiceDescDrpdwn = [
  { value : 1, label: 'Maintenance Reserves' },
  { value :2 , label : 'Rental'}
]

export const mrRangeCondition = {
  1: 'Less Than',
  2: 'Less Than or Equal To',
  3: 'Greater Than or equal To',
  4: 'In Between',
  5: 'In Between Or Equal To',
  6: 'Greater Than'
}

export const rentalRangeCondition = [
  {id:1, label: 'Less Than'},
  {id: 6, label: 'Greater Than'},
  {id: 4, label: 'In Between',}
]

export const pbhRangeCondition = [
  {id:1, label: 'Less Than Equal To'},
  {id: 6, label: 'Greater Than Equal To'},
  {id: 4, label: 'In Between Equal To',}
]

export const securityGroups = {
  lessor_admin: 29,
  technical_admin: 24
};

export const intervalUnitLinking = {
  monthly: 'Month',
  hourly: 'FH',
  cycles: 'FC'
};
