import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { globalPutService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import { PageLoader } from '../../../shared_elements'

const GenerateSummaryDialog = ({ invStatus, setGenerateSummary, getResponseBack, invData}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ isLoading, setLoading ] = useState(false);
    const [summaries, setSummaries] = useState([]);
    const [isSummaryDialogOpen, setSummaryDialogOpen] = useState({modal: false});


    const invoiceStatus =[{label:'Draft',value:1},{label:'Sent',value:2},{label:'Paid',value:3}]
    const onGenrateSummary =(value)=>{
        // setLoading(true)
        globalPutService(``, {})
        .then(response=>{
            // setLoading(false)
            if((response)){
                // setSummaries(response?.data?.data);
                setGenerateSummary(false)
                // enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        })
    }
    return (
        <>
        {isLoading ? <PageLoader />: null}
        <Dialog
            className='lease-management-modal'
            onClose={() => setGenerateSummary(false)}
            open={true}
        >
            <DialogTitle> Generate Summary Invoice's</DialogTitle>
            <DialogContent dividers='true' style={{width:'400px'}}>
                <Grid container spacing={2} >
                 <Grid item md={12}> 
                <Autocomplete
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    // onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Lessee" placeholder="Select Lessee" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
                </Grid> 
                <Grid item md={12}>  
                <Autocomplete
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    // onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Fund" placeholder="Select Fund" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
                </Grid> 
                <Grid item md={12}>  
                <Autocomplete
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    // onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Bank" placeholder="Select Bank" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
                </Grid> 
                <Grid item md={12}>  
                <Autocomplete
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    // onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Transaction Type" placeholder="Select Transaction Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
                </Grid> 
                <Grid item md={12}>  
                <Autocomplete
                    options={invoiceStatus}
                    getOptionLabel={option => option?.label}
                    value={invoiceStatus.find(item => item?.label ==invStatus?.label )}
                    id="major_assembly"
                    disableClearable={true}
                    // onChange={(e, value) => onInvStatusChange(value)}
                    renderInput={params => <TextField required {...params} label="Invoice Period" placeholder="Select Invoice Period" InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
                </Grid> 
                </Grid>
            </DialogContent>
            <DialogActions>
            <Button size='small' variant="outlined" color="primary" onClick={() => { setGenerateSummary(false) }}>Cancel</Button>
            <Button size='small' variant='contained' color='primary' onClick={()=>{onGenrateSummary();setSummaryDialogOpen({ modal: true })}}>Generate Summary Invoice</Button>
            </DialogActions>
        </Dialog>
       { isSummaryDialogOpen.modal?
        <Dialog
                className='summary-dialog'
                onClose={() => setSummaryDialogOpen(false)}
                open={true}
            >
                <DialogTitle>Summary List</DialogTitle>
                <DialogContent dividers='true'>
                    <List>
                        {/* {summaries?.map((summary, index) => ( */}
                            <ListItem >
                                {/* {summary} Adjust based on the summary structure */}
                            </ListItem>
                        {/* ))} */}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button size='small' onClick={() => setSummaryDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>:null}
        </>
    )
}
export default GenerateSummaryDialog;
