import React, { useEffect, useState } from 'react'
import { globalGetService } from '../../../utils_v2/globalApiServices'
import { EmptyCollection, PageLoader, TableListComp } from '../../../shared_elements'
import { summaryInviceHd } from '..'
import NumericLabel from 'react-pretty-numbers';
import { Grid, Paper, TableCell, TableRow, Button, Tooltip } from '@material-ui/core'
import moment from 'moment'
import { displayDateFormatShort, invoiceStatusList } from '../../../constants'
import { checkPermission, dateTransform, getCurrencyFormat } from '../../../utils_v2'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { browserHistory } from 'react-router';
import { STableLoader } from '../../../shared_elements/loaders';
import StatusChangeDialog from './StatusChangeDialog';
import { GetApp } from '@material-ui/icons';
import { globalExportService } from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import GenerateSummaryDialog from './GenerateSummaryDialog';
const SummartInvoiceList = ({ item, index, setStatusChangeList, exportInvoice }) => {
  return (
    <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
      <TableCell className="asset-info">
        <div className="flex-centered asset-wrapper" style={{ flexWrap: "nowrap" }}>
          <div className="asset">
            {item.assets.map((asset, index) => (index+1) < item?.assets.length ?`${asset}, `:`${asset}`)}
          </div>
        </div>
      </TableCell>
      <TableCell >{item.lessor && item.lessor.name ? item.lessor.name : '--'}</TableCell>
      <TableCell >
        {item.lessee && item?.lessee?.logo ? <img style={{ width: "60px" }} src={item.lessee && item?.lessee?.logo} alt="Lessee Logo" /> : <span>{item.lessee && item.lessee.name ? item.lessee.name : '--'}</span>}
      </TableCell>
      <TableCell >{item.invoice_number}</TableCell>
      <TableCell>
        <p className={item.status?.value && invoiceStatusList[item.status?.value] + ' invoice-status'}>{item.status && item.status?.label ? item.status?.label.toUpperCase() : '--'}
        {(item?.status?.value == 1 || item?.status?.value == 2 || item.status?.value == 5) && checkPermission('assembly_lease', 'invoice', 'U') ?
          <i className="fa fa-caret-down" style={{ float: 'right' }}
          onClick={(e) => setStatusChangeList({ data: item, modal: true })}
          >
          </i> : null
        }
        </p>
        <span className="payment-text">
          {((item.status?.value == 2) && item.in_due) ? <span className="invoice-status-payment" >{"Overdue By " + dateTransform(item.due_date)}</span> : ''}
        </span>
        {
          item.status?.value == 5 && item.partial_paid_date ?
            <span className="payment-text">
              <span>Partial Payment: <NumericLabel params={getCurrencyFormat({ currency: item.currency, shortFormat: false })}>{item.partial_paid_amount ? item.partial_paid_amount : '--'}</NumericLabel><br /></span>
              <span>Payment Date: {moment(item.partial_paid_date).format('MMM DD,YYYY')}</span>
              {moment(item.partial_paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date)}</span> : ''}
            </span> : null
        }
        {
          item.status?.value == 3 && item.paid_date ?
            <span className="payment-text">
              Payment Date: {moment(item.paid_date).format(displayDateFormatShort)}
              {moment(item.paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date, item.paid_date)}</span> : ''}
            </span> :
            null
        }
      </TableCell>
      <TableCell>{item.invoice_type && item.invoice_type.label ? item.invoice_type.label : '--'}</TableCell>
      <TableCell><NumericLabel params={getCurrencyFormat({ currency: item.currency, shortFormat: false })}>{item.amount}</NumericLabel></TableCell>
      <TableCell style={{ width: "170px" }}>{item.start_date ? item.start_date : '--'} <br /><span style={{ marginLeft: '30px' }}>to</span><br /> {item.end_date ? item.end_date : '--'}</TableCell>
      <TableCell>{item.date ? item.date : '--'}</TableCell>
      <TableCell>{item.due_date ? item.due_date : '--'}</TableCell>
      <TableCell>
        <Tooltip title='View'>
          <VisibilityIcon color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => browserHistory.push(`/invoices/summary/${item?.slug}`)} />
        </Tooltip>
        <Tooltip title='Export'>
          <GetApp color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => exportInvoice(item)} />
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
export default function SummaryInvoice() {
  const [summaryList, setSummaryList] = useState({})
  const [filter, setFilter] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [skeletonLoader, setSkeletonLoader] = useState(false)
  const [statusChange, setStatusChangeList] = useState(false)
  const [generateSummary, setGenerateSummary] = useState({ modal: false });
  useEffect(() => {
    getSummaryInvoiceList({},'skeletonLoader')
  }, [])
  const getSummaryInvoiceList = (query, loaderType) => {
    if (loaderType == 'skeletonLoader') {
      setSkeletonLoader(true)
    } else {
      setLoading(true)
    }
    globalGetService(`contracts/summary-invoice/list/`,query)
      .then(response => {
        setSkeletonLoader(false)
        setLoading(false)
        setSummaryList(response.data?.data)
      })
  }
  const  exportInvoice = (item) => {
    setLoading(true)
    globalExportService(`contracts/summary-invoice/${item.slug}/?download=pdf`)
        .then(response => {
            setLoading(false)
            downloadFileType(response, `Summary_invoice_${item?.invoice_number}.`, 'pdf')
        })
}

  return (
    <div>
      {isLoading ? <PageLoader /> : null}
      {skeletonLoader ? <STableLoader count={8} /> :
      <>
      <Paper square style={{padding:'6px 10px'}}>
        <Grid container spacing={1} alignItems='center' style={{justifyContent:"flex-end"}}>
          <Grid>
            {/* <Button size='small' variant='outlined' color='primary' onClick={() => setGenerateSummary({ modal: true })} >Generate Summary Invoice</Button> */}

          </Grid>
        </Grid>
      </Paper>
        <TableListComp
          heads={summaryInviceHd}
          bulkOperation={false}
          // sort_by={sort_by}
          data={summaryList?.list?.map((item, index) =>
            <SummartInvoiceList
              setStatusChangeList={setStatusChangeList}
              index={index}
              exportInvoice={exportInvoice}
              item={item} />
          )
          }
          onChangePage={(event, newPage) => getSummaryInvoiceList({ ...filter, page: newPage + 1, per_page: summaryList.pagination.per_page }, 'pageLoader')}
          onChangeRowsPerPage={(event) => getSummaryInvoiceList({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
          pagination={summaryList.pagination}
          noRecord={summaryList?.list?.length || isLoading ? null :
            <EmptyCollection
              title="No records found"
            />
          }
        />
        </>
      }
      {
        statusChange?.modal ?
        <StatusChangeDialog
        setStatusChangeList={setStatusChangeList}
        invStatus={statusChange?.data?.status}   
        invData={statusChange?.data}
        getResponseBack={getSummaryInvoiceList}
        />
        : null
      }
      {
        generateSummary.modal ?
        <GenerateSummaryDialog
        setGenerateSummary={setGenerateSummary}  
        />:null
      }
    </div>
  )
}
