import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { browserHistory } from 'react-router';
import { Dialog} from '@material-ui/core';
import { getLocalStorageInfo, getAccessToken, eraseGlobalCookie, getGlobalCookie } from '../../utils';
import { trackLogOut } from '../../utils/mixpanel'
import { getSearchResultAc } from '../GlobalSearch/actionCreators';
import { authorizationAc } from '../../User/actionCreators';
import { EditFormBar } from '../../applications/technicalInspection/Elements';
import { AddUser } from '../../User/components';
import { inviteUserAc,reportTheBugAc,sHeaderConstantAc, getNotificationBarDataAc,fetchAllFaqsAc, fetchAllFeatures } from '../actionCreators';
import { trackActivity } from '../../utils/mixpanel';
import ReportBug from './ReportBug';
import Faqs from './Faqs';
import { GLOBAL_SEARCH_RESULT } from '../GlobalSearch/actions';
import { USER_CRUD_BAR, GET_USER_CRUD, SET_USER_DATA } from '../../User/actions';
import { checkString, checkNumber, checkEmail } from '../../formValidator';
import { imgStoragePath } from '../../constants';
import { BUG_REPORT, BUY_LICENCE, NOTIFICATIONS_COUNT, NOTIFICATION_FLAG, FEATURE_MODAL } from '../actions'
import FeaturesList from './FeaturesList';
import { ToolTipHover, ApplicationSwitcher } from '../../shared';
import moment from 'moment';
import FeaturesModal from './FeaturesModal';
import WhatsNewIcon from '../../shared/assets/img/whats_new_icon.svg';
import ReportIssueIcon from '../../shared/assets/img/report_issue-icon.svg';
import FaqIcon from '../../shared/assets/img/faq_icon.svg';
import NotificationIcon from '../../shared/assets/img/notification_icon.svg';
import ShoppingCartIcon from '../../shared/assets/img/cart_icon.svg';
import caretIcon from '../../shared/assets/img/caret_icon.svg';
import config from '../../config';
class TopMainHeader extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getGlobalSearch = this.getGlobalSearch.bind(this);
    this.state = {
      dropdownOpen: false,
      changeLessor: false,
      globalFilter: {
        level: '',
        application: '',
        searchText: ''
      },
      searchText: '',
      searchFaq:'',
      msnLength: false,
      applicationSwitcher: false,
      reportBug: false,
      lessorName: false,
      toggleFaq:false,
      toggleFeatures: false,
      noticeOpen: true,
      features:[],
      tooltipOpen:false
    };
  }

  toggleFaqs = () =>{
    browserHistory.push('/faqs')
    // this.setState({
    //   toggleFaq:!this.state.toggleFaq,
    // });
    // if(this.props.location.pathname.includes('asset-view')){
    //   browserHistory.push(`/asset-view/${this.props.params.type}/${this.props.params.aircraft_slug}`)
    // }
  }
  toggleFeatures = ()=>{
    browserHistory.push('/whats-new')
    // this.setState((prevState)=>{
    //   return { toggleFeatures: !this.state.toggleFeatures}
    // })

  }
  toggle = () =>{ this.setState({
      tooltipOpen:!this.state.tooltipOpen

       }); }

  inviteUser = () => {
    const  { userData} = this.props;
    const validateNewInput = {
      first_name: checkString({value: userData.first_name, required: true, minLength: '', maxLength: '', message: 'Please Enter First Name'}),
      email: checkEmail({value: userData.email, required: true, minLength: '', maxLength: '', message: 'Please Enter Email'}),
    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.inviteUser(this.props.userData);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  componentDidMount() {
    let flag = true;
    this.unlisten = browserHistory.listen((location, action) =>  {
      flag = false;
      this.initiateGlobalFilter(location);
    });
    if(flag){
      setTimeout(() => {
        this.initiateGlobalFilter(this.props.location);
      },500);
    }
    this.props.getNotificationBarData()
    this.props.fetchFaqs();
    this.props.fetchAllFeatures();
    this.props.fetchTechConstant({constant_types:['bug_module', 'bug_severity', 'bug_classification', 'notification_type']});
    if(!getLocalStorageInfo().user.permission){
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      browserHistory.push('/login')
    }
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentWillReceiveProps(nextProps){
      if(this.props.location.pathname != nextProps.location.pathname){
        // if(nextProps.location.pathname.includes('faqs')){
        //   this.setState({
        //     toggleFaq: true
        //   })
        // }else{
        //   this.setState({
        //     toggleFaq: false
        //   })
        // }
        this.initiateGlobalFilter(nextProps.location, 'newProps')
        this.props.getNotificationBarData()
        this.props.buyLicence({
          flag: false,
          action: '',
          content: ''
        })
      }
  }

  initiateGlobalFilter = (location, string) => {
    this.setState(prevState => ({
      ...prevState,
      searchText: browserHistory.getCurrentLocation().pathname.includes('search') ? location.query.searchText : ''
    }))
    let pathArr = location.pathname.split('/');
    if(location.pathname.includes('/records/') || location.pathname.includes('/contract/') || location.pathname.includes('/maintenance/')){
      this.getMSNValue(pathArr[2])
      .then(response => {

        if(response.length){
          if(response[0].msn){
            if(response[0].msn.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = response[0].msn.length > 5 ? `MSN ...${response[0].msn.substring(response[0].msn.length - 5)}` : `MSN ${response[0].msn}`;
            this.updateMSNLevel(msn);
          }else if([6,7,8,9].includes(response[0].asset_type)){
            if(response[0].esn.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = response[0].esn.length > 5 ? `${response[0].esn.substring(0,3)} ...${response[0].esn.substring(response[0].esn.length - 5)}` : `${response[0].esn}`;
            this.updateMSNLevel(msn);
          }else if(response[0].asset_type === 2){
            if(response[0].esn.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = response[0].esn.length > 5 ? `ESN ...${response[0].esn.substring(response[0].esn.length - 5)}` : `ESN ${response[0].esn}`;
            this.updateMSNLevel(msn);
          }else if([6,7,8,9].includes(response[0].asset_type)){
            if(response[0].esn.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = response[0].esn.length > 5 ? `${response[0].esn.substring(3)} ...${response[0].esn.substring(response[0].esn.length - 5)}` : `${response[0].esn}`;
            this.updateMSNLevel(msn);
          }else if(response[0].serial_number){
            if(response[0].serial_number.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = response[0].serial_number.length > 5 ? `${response[0].asset_type == 3 ? 'APU' : response[0].asset_type == 4 ? 'LG' : 'PROPELLER'} ...${response[0].serial_number.substring(response[0].serial_number.length - 5)}` : `${response[0].asset_type == 3 ? 'APU' : response[0].asset_type == 4 ? 'LG' : 'PROPELLER'} ${response[0].serial_number}`;
            this.updateMSNLevel(msn);
          }else{
            this.updateMSNLevel(' ');
          }
          let urlPath = location.pathname;
          if(urlPath.includes('/records/')){
            this.updateFilterApps('Records');
          }
          else if(urlPath.includes('/contract/')){
            // Contract
            this.updateFilterApps('Contract');
          }else if(urlPath.includes('/maintenance/')){
            // Maintenance
            this.updateFilterApps('Maintenance');
          }else if(urlPath.includes('/assets-listing')){
            // Maintenance
            this.updateFilterApps('Fleet');
          }else{
            // this.updateFilterApps('');
            this.updateMSNLevel('Fleet');
          }
        }else if(location.pathname.includes('/fleet/')) {
          this.updateMSNLevel('Fleet');
        }else{
          // setTimeout(() => {
          //   this.initiateGlobalFilter(location);
          // },1000);
        }
      });
    }else if(location.pathname.includes('search')){
      this.getGlobalSearch();
    }
    else{
      if(location.pathname.includes('asset-view/')){
        if(Object.keys(this.props.assetView).length){
          if(this.props.assetView.msn){
            if(this.props.assetView.msn > 5){
              this.setState({
                msnLength: true
              })
            }else {
              this.setState({
                msnLength: false
              })
            }
            let msn = this.props.assetView.msn.length > 5 ? `MSN ...${this.props.assetView.msn.substring(this.props.assetView.msn.length - 5)}` : `MSN ${this.props.assetView.msn}`;
            this.updateAssetView(msn);
          }else if([6,7,8,9].includes(this.props.assetView.asset_type)){
            if(this.props.assetView.esn.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = this.props.assetView.esn.length > 5 ? `${this.props.assetView.esn.substring(0,3)} ...${this.props.assetView.esn.substring(this.props.assetView.esn.length - 5)}` : `${this.props.assetView.esn}`;
            this.updateMSNLevel(msn);
          }else if(this.props.assetView.esn){
            if(this.props.assetView.esn > 5){
              this.setState({
                msnLength: true
              })
            }else {
              this.setState({
                msnLength: false
              })
            }
            let msn = this.props.assetView.esn.length > 5 ? `ESN ...${this.props.assetView.esn.substring(this.props.assetView.esn.length - 5)}` : `ESN ${this.props.assetView.esn}`;
            this.updateAssetView(msn);
          }else if(this.props.assetView.serial_number){
            if(this.props.assetView.serial_number.length > 5){
              this.setState({
                msnLength: true
              })
            }else{
              this.setState({
                msnLength: false
              })
            }
            let msn = this.props.assetView.serial_number.length > 5 ? `${this.props.assetView.asset_type == 3 ? 'APU' : this.props.assetView.asset_type == 4 ? 'LG' : 'PROPELLER'} ...${this.props.assetView.serial_number.substring(this.props.assetView.serial_number.length - 5)}` : `${this.props.assetView.asset_type == 3 ? 'APU' : this.props.assetView.asset_type == 4 ? 'LG' : 'PROPELLER'} ${this.props.assetView.serial_number}`;
            this.updateMSNLevel(msn);
          }else{
            this.updateMSNLevel(' ');
          }
        }else{
          setTimeout(() => {
            this.initiateGlobalFilter(location)
          }, 1000);
        }
      }else{
        this.updateMSNLevel('Fleet');
      }
    }
  }
  updateAssetView = (msn) => {
    this.setState(prevState => ({
      ...prevState,
      globalFilter : {
        ...prevState.globalFilter,
        level: msn,
        application:''
      }
    }));
  }
  updateMSNLevel = (msn) => {
    if(msn == 'Fleet'){
      this.setState(prevState => ({
        ...prevState,
        globalFilter : {
          ...prevState.globalFilter,
          level: msn,
          application:''
        }
      }));
      let location = browserHistory.getCurrentLocation()
      if(location.pathname.includes('search')){
        location.query.level = msn
        location.query.application = ''
        browserHistory.push(location)
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        globalFilter : {
          ...prevState.globalFilter,
          level: msn
        }
      }));
    }
  }
  updateFilterApps = (appName) => {
    this.setState(prevState => ({
      ...prevState,
      globalFilter : {
        ...prevState.globalFilter,
        application: appName
      },
    }));
    // if(appName === ''){
    //   let location = browserHistory.getCurrentLocation()
    //   location.query.application = ''
    //   browserHistory.push(location)
    // }
  }
  getMSNValue = (slug) => {
    let msnList = getLocalStorageInfo().defaultLessor.assets;
    return new Promise(
      function(resolve, reject){
        let MSN = msnList.filter(item => item.slug == slug)
        resolve(MSN)
      }
    )
  }
  removeFilterLevel = (level) => {
    if(level != 'Fleet'){
      this.updateMSNLevel('Fleet');
    }
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('redirectURILease')
    eraseGlobalCookie('domain')
    eraseGlobalCookie('userName')
    sessionStorage.removeItem('initial_pop');
    browserHistory.push('/login');
    this.props.toggleNotification(false);
    this.props.clearHeaderbar()
  }
  toggleDropDown = () => {
   this.setState(prevState => ({
     dropdownOpen: !prevState.dropdownOpen
   }));
  }
  toggleBugReportModal = () => {
    this.props.toggleBugReport();
  }
  changeLessor = () => {
    this.setState(prevState => ({
      changeLessor: !prevState.changeLessor
    }));
  }
  updateLessor = (option) => {
    if(option){
      this.props.updateAuthToken(option)
    }
  }

  profileView = () => {
    window.open(`${config.domain.subDomian}user-profile`, '_blank')
  }

  addEditUser = () => {
    window.open(`${config.domain.subDomian}assets-listing?addUser=true`, '_blank')
  }
  onChange = (value) => {
    this.setState(prevState => ({
      ...prevState,
      searchText: value
    }))
  }
  globalSearch = (e) => {
    e.preventDefault();
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    location.query = this.state.globalFilter;
    location.query.searchText = e.target.globalSearch.value;
    if(e.target.globalSearch.value == ''){
      return;
    }
    if(this.props.params.type != undefined && this.props.params.type != null && this.props.params.type != '' && this.state.globalFilter.level !='Fleet'){
      location.pathname = '/' + this.props.params.type + '/' + this.props.params.aircraft_slug + "/search/";
    }else {
      location.pathname = '/assets/fleet/search/';
      location.query.application = '';
      location.query.level = 'Fleet';
    }
    browserHistory.push(location);
    setTimeout(() => {
      this.props.getSearchResult(location.query.searchText, location.query.application, location.query.level ,1);
    }, 500);
  }

  getGlobalSearch = () =>{
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    this.setState(prevState => ({
      ...prevState,
      globalFilter: location.query,
    }));
  }
  updateError = (key) =>{
    this.setState(prevState => ({
      ...prevState,
        error:{
          ...prevState.error,
          [key]:''
        }
    }));
  }
  handleSearchFaq = (event) => {
    if(event.length > 0){
      this.setState({
        searchFaq:event
      })
      this.props.fetchFaqs({search: this.state.searchFaq})
    }
    else {
      this.setState({
        searchFaq:""
      })
      this.props.fetchFaqs()
    }


  }

  searchGlobal = () =>{
    const location = Object.assign({}, browserHistory.getCurrentLocation());

    location.query = this.state.globalFilter;
    location.query.searchText = document.querySelector("#globalSearch").value;
    if(location.query.searchText != '' && location.query.searchText != null && location.query.searchText != undefined){
      if(this.props.params.type != undefined && this.props.params.type != null && this.props.params.type != '' && this.state.globalFilter.level !='Fleet'){
        location.pathname = '/' + this.props.params.type + '/' + this.props.params.aircraft_slug + "/search/";
      }else {
        location.pathname = '/assets/fleet/search/';
        location.query.application = '';
        location.query.level = 'Fleet';
      }
      browserHistory.push(location);
      setTimeout(() => {
        this.props.getSearchResult(location.query.searchText, location.query.application, location.query.level ,1);
      }, 500);
    }
  }

  render(){
    const { assetView, userCrudBar, userData, style, bugReport, techConstantsHeader, bugLoader, notifications,faqsList,notificationsGif, featuresList, featuresModal} = this.props;
    const userInfo = getLocalStorageInfo();
    const userName = JSON.parse(getGlobalCookie('userName'))
    const lessors = userInfo && userInfo.lessor_list && userInfo.lessor_list.map(item => item.lessor_type && item.lessor_type.value == 3 && item.owner !== undefined && item.owner !== null ?{...item,
      name: item.owner ? item.owner.name : ''}:item)
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    let is_saas = false
    Object.keys(userInfo.user.permission).map(permission => {
      if(userInfo.user.permission[permission].is_saas == true){
        is_saas = true
      }
      return permission
    })
    return(
      <header id="header" style={style}>
        {
            notifications.overdue == 2 && this.props.notificationOpen ?
            <p style={{ backgroundColor: '#ffffc2', padding: '5px 15px', fontSize: '12px', textAlign: 'center', margin: '0 -15px', position : 'ralative', zIndex: '99', border: '1px solid #ddd' }} >
                {`Please make payment within 07-${moment().format('MMM-YYYY')} to continue enjoying SPARTA.`}
                {
                  userInfo.user.permission && userInfo.user.permission.console && userInfo.user.permission.console.cart ?
                    <span> Click <Link to="/billing/invoices" style={{ color: 'rgb(21, 121, 241)', borderBottom: '1px solid #ffffff', padding: '2px 0', margin: '0px' }}>here</Link> to pay</span>
                  : null
                }
               <span style={{position: 'absolute', right: '15px', cursor: 'pointer'}} onClick={() => {this.props.toggleNotification(false)}}>X</span>
            </p>:
            notifications.overdue == 3 && this.props.notificationOpen ?
            <p style={{ backgroundColor: '#d10000', padding: '5px 15px', fontSize: '12px', textAlign: 'center', margin: '0 -15px', position : 'ralative', zIndex: '99', border: '1px solid #ddd', color: '#ffffff' }} >
              {
                notifications.overdue == 3 ?
                `Please make payment before ${moment().endOf('month').format('DD-MMM-YYYY')} to avoid any suspension.`
                :`Please make payment to continue accessing SPARTA.`
              }
              {
                userInfo.user.permission && userInfo.user.permission.console && userInfo.user.permission.console.cart ?
                  <span> Click <Link to="/billing/invoices" style={{ color: '#ffffff', fontWeight: '600' , borderBottom: '1px solid #ffffff', padding: '2px 0', margin: '0px' }}>here</Link> to pay</span>
                : null
              }
            </p>
            :null
        }
        {
            notifications.is_module_updated && this.props.notificationOpen ?
            <p style={{ backgroundColor: '#ffffc2', padding: '5px 15px', fontSize: '12px', textAlign: 'center', margin: '0 -15px', position : 'ralative', zIndex: '99', border: '1px solid #ddd' }} >
              Congratulations! Your access has been upgraded.<span> Click <Link
              onClick={() => window.open(`${config.domain.subDomian}notifications`)}
              style={{ color: 'rgb(21, 121, 241)', borderBottom: '1px solid #ffffff', padding: '2px 0', margin: '0px' }}>here</Link> to see the new features activated for you.</span>
              <span style={{position: 'absolute', right: '15px', cursor: 'pointer'}} onClick={() => {this.props.toggleNotification(false)}}>X</span>
            </p>
            :null
        }
       {/*<p style={{textAlign:'center',background:'#FF0100',color:'#ffffff',fontSize:'12px',padding:'2px'}}>We were unable to process your credit card for monthly recurring bill ( April 2019 ), Please update your payment method to avoid account suspension</p>*/}
        <Row className="flex-not-centered">
          <Col sm="5" className="flex-centered padding-right-0 text-center">
            <ul className="list-inline header-logo-search first-block">
              <li className="list-inline-item">
                <Link to={config.domain.subDomian} target="_blank" onClick={() => trackActivity('Top Main Header', { event_type: 'Clicked', event_desc: 'Sparta Logo' })}>
                  <img width="32" className="main-logo" src={imgStoragePath+"sparta_logo.png"} alt="logo" />
                </Link>
              </li>
              {
                getLocalStorageInfo().user && getLocalStorageInfo().user.permission && (getLocalStorageInfo().user.permission.contracts || getLocalStorageInfo().user.permission.technical || getLocalStorageInfo().user.permission.records || getLocalStorageInfo().user.permission.mr_calculator) ?
                  <li className="list-inline-item">
                    <h5 className="para-ui" onClick={() => this.setState({applicationSwitcher: true})}>Lease Management
                      <img width="22" src={caretIcon} alt="Icon" style={{marginLeft:'2px', verticalAlign:'bottom'}}/>
                    </h5>
                  </li>
                :<li className="list-inline-item">
                  <h5 className="para-ui">Lease Management</h5>
                </li>
              }
            </ul>
             </Col>
            <Col sm="7" className="flex-not-centered access-block">
              <ul className="list-inline header-logo-search header-action-items" style={{margin: '0px 10px 0px 0px'}}>

                <li className="list-inline-item" >
                    <a onClick={this.toggleFeatures} >
                     <span id="UncontrolledTooltipExample1"> <img width='30px' src={WhatsNewIcon} style={{cursor: 'pointer', width: '22px'}} /></span>
                     <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample1">
                       What's New!!
                    </UncontrolledTooltip>

                    </a>
                </li>
                <li className="list-inline-item" >
                    <a onClick={this.toggleBugReportModal}>
                      <span id="UncontrolledTooltipExample2"><img width='20px' src={ReportIssueIcon} style={{cursor: 'pointer'}} /></span>
                      <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample2">
                        Report an Issue
                    </UncontrolledTooltip>

                    </a>
                </li>
                <li className="list-inline-item" >
                  <span id="UncontrolledTooltipExample3"><img width='20px' src={FaqIcon}  onClick={() => this.toggleFaqs()} style={{cursor: 'pointer'}}/></span>
                  <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample3">
                        FAQs
                    </UncontrolledTooltip>

                </li>
                <li className="list-inline-item" style={location.pathname.includes('notifications') ? {borderBottom: '4px solid #2f8cff'} : {}}>
                  <span id="UncontrolledTooltipExample4" > <img width='23px'
                    onClick={() => window.open(`${config.domain.subDomian}notifications`)}
                    src={ notifications.notification_count && notificationsGif ? location.pathname.includes('notifications')?`${NotificationIcon}`:`${NotificationIcon}`:`${NotificationIcon}`} style={{cursor: 'pointer'}}/></span>
                  {notifications.notification_count ? <span className="cart-count">{notifications.notification_count}</span> : null}
                  <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample4">
                        Notifications
                    </UncontrolledTooltip>

                </li>
                {
                  is_saas && userInfo.user.permission.console && userInfo.user.permission.console.cart ?
                  <li className="list-inline-item" style={location.pathname.includes('cart') ? { borderBottom: '4px solid #2f8cff'} : {}}>
                    <img width='20px'
                      onClick={() => window.open(`${config.domain.subDomian}license/cart`,'_blank')}
                      src={ShoppingCartIcon} style={{cursor: 'pointer'}}/> {notifications.card_count ? <span className="cart-count">{notifications.card_count}</span> : null}
                  </li>
                  :null
                }
              </ul>
            <ul className="list-inline header-user-info flex-not-centered">
              {
                userInfo.defaultLessor.lessor_type != 3 ?
                <li className="list-inline-item flex-centered  help-icon">
                 Accessing
                </li>
                :null
              }
              {
                userInfo.defaultLessor.lessor_type != 3 ?
                !this.state.changeLessor ?
                <li className="list-inline-item flex-centered lessor-info" style={{position: 'relative',cursor:'pointer'}} onClick={this.changeLessor} >
                  { userInfo.defaultLessor.logo ? <img src={userInfo.defaultLessor.logo} alt={userInfo.defaultLessor.name} /> : userInfo.defaultLessor && userInfo.defaultLessor.name && userInfo.defaultLessor.name.length > 20 ? <span id="TooltipExample" onMouseOver={() => this.setState({lessorName: true})} className="lessor-name">{userInfo.defaultLessor.name.substr(0, 15) + '...'}</span> : userInfo.defaultLessor ? userInfo.defaultLessor.name : ''}
                  { userInfo && userInfo.lessor_list && userInfo.lessor_list.length > 1 ? <span style={{position: 'absolute', right: '15px'}} ><i style={{ fontStyle:"normal",color: '#3f51b5', fontSize: '16px', position: 'relative', top:'-2px', left: '2px'}}>&#9662;</i></span>:null}
                </li>:
                <li className="list-inline-item" style={{padding: '0 15px', borderRight: '1px solid #ddd'}}>
                  {
                   lessors.length > 1 ?
                   <Select
                     name="form-field-name"
                     value={userInfo.defaultLessor.id}
                     onChange={this.updateLessor}
                     options={lessors}
                     placeholder = 'Select Lessor'
                     className="custom-select-block"
                     labelKey = "name"
                     valueKey = "id"
                   />
                 :userInfo && userInfo.defaultLessor && userInfo.defaultLessor.name ?
                   userInfo.defaultLessor.name
                 :'--'
                 }
                </li>
                :
                !this.state.changeLessor ?
                <li className="list-inline-item flex-centered lessor-info" style={{position: 'relative',cursor:'pointer'}} onClick={this.changeLessor} >
                  {userInfo.defaultLessor.logo ? <img src={userInfo.defaultLessor.logo} alt={userInfo.defaultLessor.name} /> : userInfo.defaultLessor.owner && userInfo.defaultLessor.owner.name && userInfo.defaultLessor.owner.name.length > 20 ? <span id="TooltipExample" onMouseOver={() => this.setState({lessorName: true})} className="lessor-name">{userInfo.defaultLessor.owner.name.substr(0, 15) + '...'}</span> : userInfo.defaultLessor.owner && userInfo.defaultLessor.owner.name ? userInfo.defaultLessor.owner.name : '' }
                  {userInfo.lessor_list.length > 1 ? <span style={{position: 'absolute', right: '15px'}} ><i style={{ fontStyle:"normal",color: '#3f51b5', fontSize: '16px', position: 'relative', top:'-2px', left: '2px'}}>&#9662;</i></span>:null}
                </li>:
                <li className="list-inline-item" style={{padding: '0 15px', borderRight: '1px solid #ddd'}}>
                   <Select
                     name="form-field-name"
                     value={userInfo.defaultLessor.id}
                     onChange={this.updateLessor}
                     options={lessors}
                     placeholder = 'Select Lessor'
                     className="custom-select-block"
                     labelKey = "name"
                     valueKey = "id"
                   />
                </li>
              }
              {
              !this.state.changeLessor && ( userInfo.defaultLessor.logo == null || userInfo.defaultLessor.logo == '') && userInfo.defaultLessor.name.length > 20 ?
                <Tooltip placement="bottom" isOpen={this.state.lessorName} target="TooltipExample" toggle={() => this.setState({lessorName: false})}>
                  {userInfo.defaultLessor && userInfo.defaultLessor.name ? userInfo.defaultLessor.name : ''}
                </Tooltip>
                :null
              }

              <li className="list-inline-item flex-centered  user-detail-block" >
                <img className="profile-pic" src={userInfo.user.profile_pic ? userInfo.user.profile_pic: imgStoragePath+"user.png"} />
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                  <DropdownToggle caret>
                    <div className="user-blk">
                      <div>
                        <h4>{userName && userName.name ? userName.name : ''}</h4>
                        <p>{userName && userName.designation ? (userName.designation.length > 37) ? userName.designation.substring(0,35) + '..' : userName.designation :'--' }</p>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.profileView}>Profile</DropdownItem>
                    {getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.user_invite &&
                      (getLocalStorageInfo().user.permission.console.user_invite.indexOf('C') != -1 && getLocalStorageInfo().defaultLessor.lessor_type == 1) ?
                      <DropdownItem onClick={() => this.addEditUser()}>Invite User</DropdownItem> : null
                    }
                    <DropdownItem onClick={this.logout}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
        {
          location && (location.pathname.indexOf("/invoice/edit/")!=-1||location.pathname.indexOf("/fleet-cashflow/portfolio/")!=-1||location.pathname.indexOf("/engine/edit/")!=-1||location.pathname.indexOf("/project/create-engine/")!=-1||location.pathname.indexOf("/contract/mr-accrual")!=-1) ? null:
        <Link onClick={this.toggleBugReportModal} style={location.pathname.indexOf("/financial-plots/")!=-1?{ position:'fixed',right:'20px',bottom:'70px',zIndex:'2',padding:'0px 8px 0px 5px',fontSize:'11px',color:'#ffffff',backgroundColor:'#2F8CFE',boxShadow:'0 4px 6px rgba(0,0,0,0.2)',borderRadius:'.2rem',cursor:'pointer'}:{ position:'fixed',right:'15px',bottom:'15px',zIndex:'2',padding:'0px 8px 0px 5px',fontSize:'11px',color:'#ffffff',backgroundColor:'#2F8CFE',boxShadow:'0 4px 6px rgba(0,0,0,0.2)',borderRadius:'.2rem',cursor:'pointer'}}>
          <img  style={{width:'25px',padding:'5px'}}  src={imgStoragePath+"bug_rpt_icon.png"} />
            Report an issue
        </Link>
        }
        <EditFormBar
          titleContent= {<div><h3 style={{textTransform: 'capitalize'}}> Invite User </h3></div>}
           toggleEditSideBar={this.addEditUser} isOpen={userCrudBar} style={userCrudBar ? {width: '580px'} : {width: '0px'}}>
          { userCrudBar ?
          <AddUser
            type={'add'}
            user={userData}
            inviteUser = {this.props.inviteUser}
            toggleEditSideBar = {this.addEditUser}
          />
          : null}
        </EditFormBar>

        <EditFormBar
          titleContent= {
            <div>
              <h3 style={{ textTransform: 'capitalize'}}> Report an issue </h3>
              <p style={{fontSize: '12px', padding: '4px 4px 4px 0'}}>Please report only technical issues / bugs encountered while operating the SPARTA system. Issue will be reported to SPARTA IT team for action.</p>
            </div>
          }
           toggleEditSideBar={this.toggleBugReportModal} isOpen={bugReport.flag} style={bugReport.flag ? {width: '680px'} : {width: '0px'}}>
          { bugReport.flag ?
          <ReportBug
            type={'add'}
            data={bugReport.data}
            reportTheBug = {this.props.reportTheBug}
            toggleEditSideBar = {this.toggleBugReportModal}
            techConstants={techConstantsHeader}
            bugLoader={bugLoader}
          />
          :null}
        </EditFormBar>
        { this.state.applicationSwitcher ?
            <Dialog
              open={this.state.applicationSwitcher}
              onClose={() => this.setState({applicationSwitcher:false})}
              aria-labelledby="scroll-dialog-title"
              fullScreen={true}
              className='application-swicther-modal'
            >
              <ApplicationSwitcher
                handleClose={() => this.setState({applicationSwitcher:false})}
              />
            </Dialog>
            : null
        }
        {
          this.props.loginLoader ?
          <div style={{position: 'fixed', width: '100%', height: '100%', zIndex: '9999'}} onClick={() => null}>
            <div className="list-loader" style={{position: 'fixed', left: '50%', top: '50%', transform: 'translate(-60%, -60%)', zIndex:10000, background: 'rgba(255,255,255,0.8)', border:'1px solid #d7d7d7',borderRadius:'4px', padding: '15px' }} ><img style={{width: '100px'}} src={imgStoragePath+"page-loader.gif"} alt="icn" /><p style={{marginTop:"10px", fontWeight:'700',fontSize:'14px'}} className="text-center"></p></div>
          </div>
          :null
        }
      </header>
    )
  }
}
const mapStateToProps = state => ({
  msnSwitcher: state.sharedReducers.msnSwitcher,
  bugReport: state.sharedReducers.bugReport,
  assetView: state.userAssetReducer.assetView,
  loginLoader: state.userAssetReducer.loginLoader,
  userData:state.userAssetReducer.userData,
  userCrudBar:state.userAssetReducer.userCrudBar,
  techConstantsHeader: state.sharedReducers.techConstantsHeader,
  bugLoader: state.sharedReducers.bugLoader,
  notifications: state.sharedReducers.notifications,
  notificationsGif:state.sharedReducers.notificationsGif,
  faqsList:state.sharedReducers.faqsList,
  notificationOpen:state.sharedReducers.notificationOpen,
  featuresList: state.sharedReducers.featuresList,
  featuresModal:state.sharedReducers.featuresModal
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAuthToken:(id, pageType) => dispatch(authorizationAc(id, pageType)),
    fetchFaqs:(query) => dispatch(fetchAllFaqsAc(query)),
    fetchAllFeatures:() => dispatch(fetchAllFeatures()),
    getSearchResult: (query, application, level, pageNumber) => dispatch(getSearchResultAc(ownProps.params, query, application, level, pageNumber)),
    inviteUser: (data) => dispatch(inviteUserAc(data)),
    reportTheBug:(data)=>dispatch(reportTheBugAc(data)),
    fetchTechConstant : (constantTypes) => dispatch(sHeaderConstantAc(constantTypes)),
    toggleBugReport:()=> dispatch({
      type: BUG_REPORT,
      payload: {}
    }),
    addEditUser: () => dispatch({
      type: USER_CRUD_BAR,
      payload: {}
    }),
    onBlur: (value, type) => dispatch({
      type: SET_USER_DATA,
      payload:{
        type,
        value
      }
    }),
    getNotificationBarData: () => dispatch(getNotificationBarDataAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    toggleNotification: (flag) => dispatch({
      type: NOTIFICATION_FLAG,
      payload: flag
    }),
    clearHeaderbar: () => dispatch({
      type: NOTIFICATIONS_COUNT,
      payload: {
        overdue: 1,
        card_count: 0,
        notification_count: 0
      }
    }),
    featureModalToggle:(flag)=>dispatch({
        type:'FEATURE_MODAL',
        payload: flag
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMainHeader));
